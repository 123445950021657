/* Add the following styles to your existing CSS file or create a new one */

body {
  background-image: url('../assets/backgroundimage.jpg');
  font-family: 'Eczar', serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.home-page {
  text-align: center;
}

.insideStyles {
  background-color: white;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav {
  background-color: #1c7ea3;
  top: 0;
  padding: 10px;
  border-bottom: 1px solid #e07b39;
  width: 100%;
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.navLink {
  margin-left: 34%;
  text-align: center;
}

.linkId {
  border-right: 1px solid #e07b39;
}

.linkId:hover {
  background-color: #e07b39;
}

.linkId:last-child {
  border: none;
}

.welcome {
  margin-top: 40px;
  font-size: 20px;
}

.main-outer-container {
  padding: 2%;
  margin-bottom: 100px;
}

.navigationBar {
  height: 50px;
  background-color: #e07b39;
  color: white;
  text-align: center;
  border-bottom: 1px solid #1c7ea3;
  margin-bottom: 2%;
  padding: 1%;
}

.para-left,
.para-right {
  color: #141414;
}

.each-fade {
  max-width: 100%;
  height: 400px;
  display: block;
}

.jumbo {
  margin-top: 3%;
  text-align: center;
  background-color: white;
  color: #141414;
  border: 1px solid #1c7ea3;
  flex: 1;
  padding-bottom: 60px;
  margin-bottom: 200px;
}

.jumbo:hover {
  box-shadow: 0em 0 0.9em #1c7ea3;
}

.mainContainer {
  padding: 2%;
  height: 200px;
  width: auto;
}

.noPage {
  color: #1c7ea3;
}

.hrcolor {
  background-color: #e07b39;
}

.allImg {
  width: 100%;
  height: auto;
}

.allImg:hover {
  box-shadow: 0em 0 0.9em white;
}

.products-para {
  text-align: center;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.productsHeadList {
  text-align: left;
}

.productsList {
  text-decoration: none;
  text-align: left;
}

.headerColor {
  color: #e07b39;
}

@media only screen and (max-width: 900px) {
  .navLink {
    margin: 0;
    color: black;
    text-align: center;
  }

  .linkId {
    border: none;
  }

  .navigationBar {
    border-bottom: none !important;
    height: auto;
  }

  .row {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .main-outer-container {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-outer-container {
    margin-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .main-outer-container {
    margin-bottom: 80px;
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #1c7ea3;
  border-top: solid 2px #e07b39;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
